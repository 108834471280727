<template>
  <div class="enter-package flexbox vertical">
    <header class="page-header flexbox space-bt">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ $t('EnterPackage.rwdw6u') }}</span>
      <van-icon name="ellipsis" class="text-white" />
    </header>
    <main class="flex-fill page-body">
      <div class="form round">
        <van-field label="快递单号" required v-model="orderNo"></van-field>
        <van-field label="物品名称" v-model="name"></van-field>
        <van-field label="备注" v-model="note"></van-field>
        <van-field label="申报货值" v-model="declarePrice"></van-field>
      </div>
      <div class="note">
        {{ $t('EnterPackage.aaexyg') }}
      </div>
      <div class="x-center">
        <van-button type="info" @click="onSubmit">{{
          $t('EnterPackage.52vibt')
        }}</van-button>
      </div>
      <div class="address round">
        <div class="theme-color indent">
          {{ $t('EnterPackage.kqs54j') }}： {{ stock_name }}
        </div>
        <div class="indent">
          <div>{{ $t('EnterPackage.5jllo7') }} {{ stock_rec_name }}</div>
          <div>{{ $t('EnterPackage.bzs54i') }} {{ stock_rec_tel }}</div>
          <div>{{ $t('EnterPackage.f4o25k') }} {{ stock_rec_zipcode }}</div>
          <div>{{ $t('EnterPackage.uc0c1f') }} {{ stock_rec_addr }}</div>
          <div>{{ $t('EnterPackage.8qeqyj') }} {{ stock_rec_remark }}</div>
          <div>{{ $t('EnterPackage.sb9f6s') }} {{ stock_rec_worktime }}</div>
          <div>{{ $t('EnterPackage.o6hjut') }}</div>
        </div>
        <div class="tip">
          {{ $t('EnterPackage.fljdjs') }}
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { addPackage, getStockInfo } from '@/api/deliver.js';
export default {
  data() {
    return {
      orderNo: '',
      name: '',
      note: '',
      declarePrice: 0,
      stock_name: '',
      stock_rec_name: '',
      stock_rec_tel: '',
      stock_rec_zipcode: '',
      stock_rec_addr: '',
      stock_rec_remark: '',
      stock_rec_worktime: '',
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getStockInfo().then(res => {
        if (res.Result == true) {
          var stockInfo = res.DataDict['data'];
          this.stock_name = stockInfo.name;
          this.stock_rec_name = stockInfo.recv;
          this.stock_rec_tel = stockInfo.tel;
          this.stock_rec_zipcode = stockInfo.code;
          this.stock_rec_addr = stockInfo.addr;
          this.stock_rec_remark = stockInfo.note;
          this.stock_rec_worktime = stockInfo.worktime;
        }
      });
    },
    reset() {
      this.orderNo = '';
      this.name = '';
      this.note = '';
      this.declarePrice = 0;
    },
    onSubmit() {
      addPackage({
        name: this.name,
        deliveryNo: this.orderNo,
        remarks: this.note,
        declarePrice: this.declarePrice,
      }).then(res => {
        if (res.Result) {
          this.$toast(res.Info);
          this.reset();
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.round {
  border-radius: rem(10px);
}
.note {
  color: #b8b8b8;
  font-size: 12px;
  line-height: 1.5;
  margin: rem(20px) 0;
}
.van-button {
  height: rem(80px);
  width: rem(500px);
  background-color: $theme-color;
  border: none;
  border-radius: rem(25px);
}
.x-center {
  margin: rem(40px) 0;
}
.address {
  background: rgba(#9c9bb4, 0.3);
  padding: rem(20px);
  padding-bottom: rem(40px);
}

.indent {
  margin-left: rem(40px);
  line-height: 2;
}
.tip {
  padding: rem(32px);
  background: #f6f6f6;
  border-radius: rem(10px);
}
.text-white {
  color: #fff;
}
</style>
